import React from 'react'
import NewsUpdates from '../NewsUpdates';


const NewsUpdated = () => {
    return (
        <div className='text-center py-4'>
          <div>
            <NewsUpdates />
          </div>
      </div>
    );
  }
  

export default NewsUpdated